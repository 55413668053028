<template>
  <main>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <!-- <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="home-tab"
          data-toggle="tab"
          href="#home"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          >VALE</a
        >
      </li> -->
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
          aria-selected="false">REPORTE EXAMENES</a>
      </li>
      <!-- <li class="nav-item" role="presentation">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">SITUACIONES PERSONALES</a>
            </li> -->
      <!-- <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="apgar-tab"
          data-toggle="tab"
          href="#apgar"
          role="tab"
          aria-controls="apgar"
          aria-selected="false"
          >APGAR</a
        >
      </li> -->
    </ul>
    <div class="tab-content" id="myTabContent">
      <!-- <div
        class="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <indexVale
          :idHistoria="idHistoria"
          :finalizada="finalizada"
          :idUsuario="idUsuario"
          :usuario="usuario"
        ></indexVale>
      </div> -->
      <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <indexExamenes :idHistoria="idHistoria" :finalizada="finalizada" :id-tipo-historia="idTipoHistoria"
          :usuario="usuario" :idUsuario="idUsuario"></indexExamenes>
      </div>
     <!--  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
        <indexSituacionesPersonales :usuario="usuario" :idHistoria="idHistoria" :finalizada="finalizada"
          :idUsuario="idUsuario"></indexSituacionesPersonales>
      </div> -->
      <!-- <div
        class="tab-pane fade"
        id="apgar"
        role="tabpanel"
        aria-labelledby="apgar-tab"
      >
        <apgarFamiliar
          :idHistoria="idHistoria"
          :usuario="usuario"
          :finalizada="finalizada"
          :id-tipo-historia="idTipoHistoria"
        ></apgarFamiliar>
      </div> -->
    </div>
  </main>
</template>
<script>
//import indexVale from "../base/baseVale/indexVale";
import indexExamenes from "../base/baseReporteExamenesAdolescente/indexExamenes";
//import indexSituacionesPersonales from "../base/baseSituacionesPersonales/indexSituacionesPersonales";
//import apgarFamiliar from "../base/baseApgarFamiliar/apgarHistoria";
export default {
  props: ["idHistoria", "finalizada", "idUsuario", "usuario", "idTipoHistoria"],
  components: {
    indexExamenes
  },
};
</script>
